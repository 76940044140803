import { template as template_a03d696e516f44b0b43e7aa2410bd0b8 } from "@ember/template-compiler";
const WelcomeHeader = template_a03d696e516f44b0b43e7aa2410bd0b8(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
