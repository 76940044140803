import { template as template_2007e64f1ea942a489535d871f93e94e } from "@ember/template-compiler";
const FKControlMenuContainer = template_2007e64f1ea942a489535d871f93e94e(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
